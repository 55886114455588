import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import {
  isLayoutTextOnImage,
  isLayoutMobile,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import withLayoutProps from '../../hoc/with-layout-props';
import PaginationRegular from '../../components/pagination-regular';
import styles from './pagination.scss';

export const Pagination = ({
  page,
  pageSize,
  entityCount,
  createPageUrl,
  handleNavigation,
  layoutType,
  section,
  onChange,
  useDescriptionColor,
  containerClassName,
  activeClass,
}) => {
  const isMobile = isLayoutMobile(layoutType);
  useDescriptionColor = useDescriptionColor || !isLayoutTextOnImage(layoutType);
  const className = classNames(
    styles.container,
    isMobile && styles.mobile,
    isMobile && `blog-post-${section}-background-color`,
    useDescriptionColor ? `blog-post-${section}-description-color` : 'blog-text-color',
    containerClassName,
  );
  const arrowFillClass = useDescriptionColor && `blog-post-${section}-description-fill`;
  const fontClassName = `blog-post-${section}-description-style-font`;
  return (
    <div className={className}>
      <PaginationRegular
        activeClass={activeClass}
        page={page}
        pageSize={pageSize}
        entityCount={entityCount}
        showPosition={isMobile}
        arrowFillClass={arrowFillClass}
        onChange={onChange}
        pageClass={fontClassName}
        positionClass={fontClassName}
        component={PageLink(createPageUrl, handleNavigation)}
      />
    </div>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number,
  entityCount: PropTypes.number,
  createPageUrl: PropTypes.func.isRequired,
  layoutType: PropTypes.number.isRequired,
  section: PropTypes.string.isRequired,
  handleNavigation: PropTypes.func,
  onChange: PropTypes.func,
  useDescriptionColor: PropTypes.bool,
  activeClass: PropTypes.string,
};

function PageLink(createPageUrl, handleNavigation = () => {}) {
  // eslint-disable-next-line react/prop-types
  return ({ children, page, isDisabled, isActive, ...props }) => {
    if (isActive || isDisabled) {
      return <div {...props}>{children}</div>;
    }
    return (
      <a href={createPageUrl(page)} onClick={event => handleNavigation(event, page)} {...props}>
        {children}
      </a>
    );
  };
}

export default withLayoutProps()(Pagination);
