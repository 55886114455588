import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'reset-css';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import { initI18n } from '@wix/communities-blog-client-common/dist/src/services/i18n-provider';
import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import DirectionProviderWrapper from '../../../common/components/direction-provider-wrapper';
import { getIsRTL, getLanguage, isSeo } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import '../../../rich-content/components/rich-content-editor/rich-content-editor.global.scss';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { initDebug } from '../../../common/services/debug';
import { initOuterUrl } from '../../../common/services/get-outer-url';
import { getIsDebugEnabled } from '../../../common/store/app-config/app-config-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import PermissionsProvider from '../../../common/components/permissions-provider';
import PostList from '../post-list';
import styles from './app-root.scss';
import { ImgProvider, RENDER_IMAGE, RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY } from '@wix/communities-image-lib';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import { isInWix } from '../../../common/services/is-in-wix';
import { getLayoutMargins } from '../../../common/selectors/app-settings-selectors';
import { SECTION_POST_LIST } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { getLayoutType } from '../../../common/selectors/layout-selectors';

class AppRoot extends React.Component {
  constructor(props) {
    super(props);
    initDebug(this.props.isDebugEnabled);
    initOuterUrl(this.props.sectionUrl);
  }

  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
  }

  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { language, translations, direction, isSeo, padding } = this.props;
    const i18n = this.getI18nConfig(language, translations);

    return (
      <ResponsiveListener>
        <PermissionsProvider>
          <I18nextProvider i18n={i18n}>
            <DirectionProviderWrapper>
              <DirectionProvider direction={direction}>
                <ImgProvider
                  renderMode={isSeo ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY}
                  {...(isWebpSupported() ? { format: 'webp' } : {})}
                >
                  <div style={{ padding }} className={styles.baseStyles}>
                    <PostList />
                    <AppLoaded />
                  </div>
                </ImgProvider>
              </DirectionProvider>
            </DirectionProviderWrapper>
          </I18nextProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = state => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  direction: getIsRTL(state) ? DIRECTIONS.RTL : DIRECTIONS.LTR,
  isDebugEnabled: getIsDebugEnabled(state),
  sectionUrl: getSectionUrl(state),
  isSeo: isSeo(state),
  padding: getLayoutMargins({ state, section: SECTION_POST_LIST, layoutType: getLayoutType(state, SECTION_POST_LIST) }),
});

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
