import { get } from 'lodash';
import { EXPERIMENT_DEFAULT_MOBILE_LAYOUT_SIDE_BY_SIDE } from '@wix/communities-blog-experiments';
import { getIsCreatedWithResponsiveEditor } from './app-settings-selectors';
import { getAppSettings } from './app-settings-base-selectors';
import { getLayoutTypePath } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import {
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_FULL_POST,
  LAYOUT_ONE_COLUMN_MOBILE,
  LAYOUT_SIDE_BY_SIDE_MOBILE,
  LAYOUT_PG_GRID,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import { isSeo, getIsMobile, getIsDesktop } from '../store/basic-params/basic-params-selectors';
import { getSection } from './section-selectors';
import { isExperimentEnabled } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';
import { getBundleName } from '../store/app-config/app-config-selectors';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../../post-list-widget/constants/post-list-widget';

// use withLayoutProps HOC for app

export const getDefaultLayout = (state, isMobile) => {
  if (isMobile) {
    const isDefaultMobileLayoutSideBySideExperimentEnabled = isExperimentEnabled(
      state,
      EXPERIMENT_DEFAULT_MOBILE_LAYOUT_SIDE_BY_SIDE,
    );

    return isDefaultMobileLayoutSideBySideExperimentEnabled ? LAYOUT_SIDE_BY_SIDE_MOBILE : LAYOUT_ONE_COLUMN_MOBILE;
  }

  if (getBundleName(state) === POST_LIST_WIDGET_BUNDLE_NAME) {
    return LAYOUT_PG_GRID;
  }

  const isCreatedWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);
  return isCreatedWithResponsiveEditor ? LAYOUT_PG_GRID : LAYOUT_SIDE_BY_SIDE;
};

export const getLayoutType = (state, section, isMobile) => {
  if (!section) {
    return undefined;
  }

  isMobile = typeof isMobile !== 'undefined' ? isMobile : getIsMobile(state);
  const defaultLayout = getDefaultLayout(state, isMobile);
  return get(getAppSettings(state), getLayoutTypePath(section, isMobile), defaultLayout);
};

export const isMasonryLayoutSupported = state => !isSeo(state);

export const isFullPostLayout = (state, section) =>
  getLayoutType(state, section || getSection(state, true)) === LAYOUT_FULL_POST;

export const isRceRequired = (state, section) => getIsDesktop(state) && isFullPostLayout(state, section);

export const isExcludePostContentSupported = (state, section) => !isRceRequired(state, section);
