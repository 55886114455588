import {
  LAYOUT_CARD_SMALL,
  LAYOUT_CARD_MEDIUM,
  LAYOUT_CARD_LARGE,
  LAYOUT_CARD_PROFILE,
  LAYOUT_FULL_POST,
  LAYOUT_MOBILE,
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_GRID,
  LAYOUT_TEXT_ON_IMAGE_SMALL,
  LAYOUT_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_ONE_COLUMN_MOBILE,
  LAYOUT_SIDE_BY_SIDE_MOBILE,
  isLayoutNameTextOnImage,
  LAYOUT_TEXT_ON_IMAGE_MOBILE,
  LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_PG_CARD_MEDIUM,
  LAYOUT_PG_SIDE_BY_SIDE,
  LAYOUT_PG_GRID,
  LAYOUT_PG_ONE_COLUMN,
  LAYOUT_SLIDER,
  LAYOUT_LIST,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import {
  LIST_COMPONENT_MASONRY,
  LIST_COMPONENT_SIMPLE,
  LIST_COMPONENT_MOBILE,
  ITEM_COMPONENT_LIST_ITEM,
  ITEM_COMPONENT_POST,
  ITEM_COMPONENT_MOBILE_SIDE_BY_SIDE,
  ITEM_COMPONENT_MOBILE_TOI,
} from '@wix/communities-blog-client-common/dist/src/constants/post-list-components';

const LAYOUT_CONFIG = {
  [LAYOUT_CARD_SMALL]: {
    listComponentName: LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 3,
          description: 3,
        },
        withoutCover: {
          title: 3,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_CARD_MEDIUM]: {
    listComponentName: LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: {
          title: 3,
          description: 3,
        },
        withoutCover: {
          title: 3,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_CARD_LARGE]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: {
          title: 2,
          description: 2,
        },
        withoutCover: {
          title: 2,
          description: 2,
        },
      },
    },
  },
  [LAYOUT_CARD_PROFILE]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: {
          title: 2,
          description: 2,
        },
        withoutCover: {
          title: 2,
          description: 2,
        },
      },
    },
  },
  [LAYOUT_FULL_POST]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_POST,
    itemConfig: {
      displayFooterIcons: false,
    },
  },
  [LAYOUT_MOBILE]: {
    listComponentName: LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 0,
          description: 3,
        },
        withoutCover: {
          title: 0,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_SIDE_BY_SIDE]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: {
          title: 3,
          description: 2,
        },
        withoutCover: {
          title: 2,
          description: 2,
        },
      },
    },
  },
  [LAYOUT_GRID]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 3,
          description: 0,
        },
        withoutCover: {
          title: 3,
          description: 4,
        },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_SMALL]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 2,
          description: 0,
        },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_MEDIUM]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 2,
          description: 0,
        },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_ONE_COLUMN_MOBILE]: {
    listComponentName: LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 0,
          description: 3,
        },
        withoutCover: {
          title: 0,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_SIDE_BY_SIDE_MOBILE]: {
    listComponentName: LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_MOBILE_SIDE_BY_SIDE,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 0,
          description: 3,
        },
        withoutCover: {
          title: 0,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_MOBILE]: {
    listComponentName: LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_MOBILE_TOI,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 2,
          description: 0,
        },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 0,
      },
    },
  },
  [LAYOUT_PG_CARD_MEDIUM]: {
    listComponentName: LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
  [LAYOUT_PG_SIDE_BY_SIDE]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        title: 2,
        description: 2,
      },
    },
  },
  [LAYOUT_PG_GRID]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 4,
      },
    },
  },
  [LAYOUT_PG_ONE_COLUMN]: {
    listComponentName: LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
  [LAYOUT_SLIDER]: {
    itemConfig: {
      hideFooter: true,
      hideHeader: true,
      hideDescription: true,
      lineCount: {
        title: 3,
        description: 3,
      },
      displayFooterIcons: true,
    },
  },
  [LAYOUT_LIST]: {
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
};

export const getLayoutConfig = layoutType => LAYOUT_CONFIG[layoutType];
export const getLineCounts = ({ lineCount }, isWithCover) =>
  isWithCover ? lineCount.withCover : lineCount.withoutCover;

export const createFeedClassNameGenerator = section => (...classes) =>
  classes.map(className => `blog-post-${section}-${className}`);

export const getFeedColorClassName = (layoutName, name) =>
  `${isLayoutNameTextOnImage(layoutName) ? 'TOI-' : ''}${name}`;
