export const ROUTE_404 = '/404';
export const ROUTE_HOME = '/(page/:page(/))';
export const ROUTE_CATEGORY = '/category/:categorySlug(/:subcategorySlug)(/page/:page)(/)';
export const ROUTE_CATEGORIES = '/categories/:categorySlug(/:subcategorySlug)(/page/:page)(/)';
export const ROUTE_SEARCH = '/search(/:query)';
export const ROUTE_ARCHIVE = '/archive/:year/:month(/page/:pageIndex)(/)';
export const ROUTE_TAGS = '/tags/:tagSlug(/page/:page)(/)';

export const ROUTE_LOGIN = '/login';
export const ROUTE_ACCOUNT_SUSPENDED = '/account-suspended';
