import { POST_LIST_SETTINGS_PARAMS } from '@wix/communities-blog-client-common/dist/src/constants/post-list-widget-constants';
import { getAppSettingsValue, getAppSettingsNumber } from '../../common/selectors/app-settings-base-selectors';

const { paginationType, entityCount, postsPerPage } = POST_LIST_SETTINGS_PARAMS;

export const getPostListWidgetPageSize = state => {
  const _paginationType = getAppSettingsValue({
    state,
    key: paginationType.appSettingsPath,
    fallback: paginationType.defaultValue,
  });
  const _entityCount = getAppSettingsNumber(state, entityCount.wixParam, entityCount.defaultValue);
  const _postsPerPage = getAppSettingsNumber(state, postsPerPage.wixParam, postsPerPage.defaultValue);

  return _paginationType === paginationType.values.PAGINATED ? _postsPerPage : _entityCount;
};
